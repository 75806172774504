import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/functions'

const config = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID
}

if (!firebase.apps.length) {
  firebase.initializeApp(config)
}

const dbRef = firebase.firestore()
dbRef.settings({experimentalForceLongPolling: true, merge: true})

const authRef = firebase.auth()
const functionsRef = firebase.functions()
const facebook_provider = new firebase.auth.FacebookAuthProvider()
const google_provider = new firebase.auth.GoogleAuthProvider()
const phone_provider = firebase.auth.PhoneAuthProvider.PROVIDER_ID
const persistenceNone = firebase.auth.Auth.Persistence.NONE
const persistenceSession = firebase.auth.Auth.Persistence.SESSION
const persistenceLocal = firebase.auth.Auth.Persistence.LOCAL
const recaptcha = (elm, config) => {
  return new firebase.auth.RecaptchaVerifier(
    elm, config,
  )
}

const emailAuth = (email, password) => firebase.auth.EmailAuthProvider.credential(email, password)

export {
  dbRef,
  authRef,
  functionsRef,
  google_provider,
  facebook_provider,
  phone_provider,
  recaptcha,
  persistenceNone,
  persistenceSession,
  persistenceLocal,
  emailAuth,
}

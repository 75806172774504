import {authRef, google_provider, facebook_provider, emailAuth} from '../firebase/firebase'
import * as routes from '../constants/routes'

// Send Confirmation email.
export const reloadUser = () =>
  authRef.currentUser.reload()

// Send Confirmation email.
export const sendConfirmationEmail = () =>
  authRef.currentUser.sendEmailVerification()

// Send Confirmation email.
export const confirmEmail = (actionCode) =>
  authRef.applyActionCode(actionCode)

// Sign Up with email and password.
export const doCreateUserWithEmailAndPassword = (mail, password) =>
  authRef.createUserWithEmailAndPassword(mail, password)

// Sign In with email and password.
export const doSignInWithEmailAndPassword = (mail, password) =>
  authRef.signInWithEmailAndPassword(mail, password)

// Sign In With Google Provider.
export const doSignInWithGoogle = () =>
  authRef.signInWithPopup(google_provider)

// Sign In With Facebook Provider.
export const doSignInWithFacebook = () =>
  authRef.signInWithPopup(facebook_provider)

// Sign In With Token.
export const doSignInWithCustomToken = (token) =>
  authRef.signInWithCustomToken(token)

// Set up test recaptcha.
export const doAllowTests = () =>
  authRef.settings.appVerificationDisabledForTesting = true

// Sign out.
export const doSignOut = () => {
  return authRef.signOut()
}

// Password Reset.
export const doPasswordReset = (mail) =>
  authRef.sendPasswordResetEmail(mail)

export const verifyPasswordResetCode = (actionCode) =>
  authRef.verifyPasswordResetCode(actionCode)

export const doDeleteCurrentUser = () =>
  authRef.currentUser.delete()

export const confirmPasswordReset = (actionCode, newPass) => {
  return authRef.confirmPasswordReset(actionCode, newPass)
}

// Update Account with a provider.
export const doAccountCredentalsUpdate = (email, password) =>
  authRef.currentUser.linkAndRetrieveDataWithCredential(emailAuth(email, password))
    .then(function (usercred) {
        var user = usercred.user
        console.log('Account linking success', user)
      },
      function (error) {
        console.log('Account linking error', error)
      })

// Process Firebase Auth providers for an email.
export const processSignInMethodsForEmail = (email, staticTexts, dispatch, setErrorPage, error) => {
  // Prepare General message for already existing account.
  let message = staticTexts.FacebookErrorExistingAccountGeneralMessage ?
    staticTexts.FacebookErrorExistingAccountGeneralMessage :
    'An account already exists with the same email address but @provider sign-in credentials. Sign in using a provider associated with this email address.'
  let buttonText = 'Sign In with Email'

  // If the error is for too many requests, return the error.
  if (error.code === 'auth/too-many-requests') {
    return new Promise((resolve) => {
      resolve(false)
    })
  }

  // Fetch the sign in providers for the email.
  return authRef.fetchSignInMethodsForEmail(email)
    .then(providers => {
      if (providers.length !== 0) {
        // Process users with Google and Email/Password providers.
        if (providers.includes('google.com') && providers.includes('password')) {
          message = message.replace('@provider', 'Google and Email')
          buttonText = staticTexts.FacebookErrorExistingAccountGoogleEmailButton ?
            staticTexts.FacebookErrorExistingAccountGoogleEmailButton :
            'Sign In with Google or Email'
          dispatch(setErrorPage(staticTexts.GeneralErrorTitle, message, 'error', false, buttonText, routes.SIGN_IN_EMAIL))
        }
        else if (providers.includes('google.com')) {
          // Process users with Google provider.
          message = message.replace('@provider', 'Google')
          buttonText = staticTexts.FacebookErrorExistingAccountGoogleButton ?
            staticTexts.FacebookErrorExistingAccountGoogleButton :
            'Sign In with Google'
          dispatch(setErrorPage(staticTexts.GeneralErrorTitle, message, 'error', false, buttonText, routes.SIGN_IN_EMAIL))
        }
        else if (providers.includes('facebook.com')) {
          // Process users with Facebook provider.
          message = message.replace('@provider', 'Facebook')
          buttonText = staticTexts.FacebookErrorExistingAccountFacebookButton ?
            staticTexts.FacebookErrorExistingAccountFacebookButton :
            'Sign In with Facebook'
          dispatch(setErrorPage(staticTexts.GeneralErrorTitle, message, 'error', false, buttonText, routes.SIGN_IN_EMAIL))
        }
        else if (providers.includes('password')) {
          if (error.code === 'auth/wrong-password') {
            return false
          }
          // Process users with Email provider.
          message = message.replace('@provider', 'Email')
          buttonText = staticTexts.FacebookErrorExistingAccountPasswordButton ?
            staticTexts.FacebookErrorExistingAccountPasswordButton :
            'Sign In with Email'
          dispatch(setErrorPage(staticTexts.GeneralErrorTitle, message, 'error', false, buttonText, routes.SIGN_IN_EMAIL))
        }
        return true
      }
      return false
    })
    .catch(error => {
      console.error(error)
      dispatch(setErrorPage(staticTexts.GeneralErrorTitle, error.message, 'error', false, staticTexts.GeneralErrorClose, false, false, true))
    })
}


import React, {useEffect} from 'react'
import {auth} from '../../../actions'
import Preloader from '../../Preloader'
import {useSelector} from 'react-redux'
import {useHistory} from 'react-router-dom'
import * as routes from '../../../constants/routes'
import * as Sentry from '@sentry/browser'

const SignOut = () => {
  const authUser = useSelector(state => state.sessionState.authUser)
  const history = useHistory()

  useEffect(() => {
    auth.doSignOut()
      .then(() => history.push(routes.SIGN_IN_EMAIL))
      .catch((error) => {
        Sentry.captureException(error)
        console.error(error)
      })
  }, [authUser])

  return (
    <Preloader title='Signin you out. Please wait.'/>
  )
}

export default SignOut
import { combineReducers } from 'redux';
import sessionReducer from './session';
import sessionDBReducer from './sessionDB';
import userReducer from './user';
import accountReducer from './account';
import dealsReducer from './deals';
import textsReducer from './texts';
import brandReducer from './brand';
import plansReducer from './plans';
import productsReducer from './products';
import configReducer from './config';
import quizReducer from './quiz';
import errorReducer from './error';
import militaryInfoReducer from './militaryInfo';
import captureIdReducer from './captureId';

const rootReducer = combineReducers({
  sessionState: sessionReducer,
  sessionDBState: sessionDBReducer,
  userState: userReducer,
  accountState: accountReducer,
  dealsState: dealsReducer,
  textsState: textsReducer,
  brandState: brandReducer,
  plansState: plansReducer,
  productsState: productsReducer,
  configState: configReducer,
  quizState: quizReducer,
  errorState: errorReducer,
  militaryInfoState: militaryInfoReducer,
  captureIdState: captureIdReducer,
});

export default rootReducer;

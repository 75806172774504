const INITIAL_STATE = {
  status: "loading",
  quiz: [],
  answers: {},
  error: '',
};

function quizReducer(state = INITIAL_STATE, action) {
  switch(action.type) {
    case 'FETCH_QUIZ' : {
      if (action.payload) {
        return {
          status: "loaded",
          quiz:  action.payload,
          answers: state.answers,
          error: '',
        }
      }
      else {
        return false
      }
    }
    case 'QUIZ_ANSWERS' : {
      return {
        status: "answered",
        quiz: state.quiz,
        answers: action.payload,
      };
    }
    case 'QUIZ_RESET' : {
      return INITIAL_STATE;
    }
    case 'FETCH_QUIZ_ERROR' : {
      return {
        status: "answered",
        quiz: state.quiz,
        answers: state.answers,
        error: action.payload,
      };
    }
    default : return state;
  }
}

export default quizReducer;

import React from 'react'
import AppBar from '@material-ui/core/AppBar'
import {makeStyles} from '@material-ui/core/styles'
import * as routes from '../../../constants/routes'
import {useHistory} from 'react-router-dom'

const useStyles = makeStyles(theme => ({
  header: {
    background: '#F8F9FA',
    marginBottom: '40px',
    '@media (min-width: 494px)': {
      marginBottom: '60px',
    },
    padding: '10px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: 'wrap',
    flexDirection: 'row',
  },
  logos: {
    flex: '1 0 auto',
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    flexDirection: 'row',
  },
  logoPartner: {
    width: 'auto',
    maxHeight: '40px',
    cursor: 'pointer',
    margin: '6px 16px 6px 0',
    borderLeft: '1px solid #666',
    paddingLeft: '16px',
    padding: '4px 0 4px 16px'
  },
  logo: {
    width: 'auto',
    maxHeight: '40px',
    cursor: 'pointer',
    margin: '10px 16px',
  },
  partnerLogoWrapper: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    color: '#001E33',
    fontWeight: '400',
    fontSize: '20px',
    margin: '10px 16px',
  },
  partnerLogo: {
    maxHeight: '100%',
  },
  container: {},
  marginHelper: {
    marginTop: '5px',
    marginBottom: '11px',
  },
  field: {
    borderBottom: '0 none',
    borderTopLeftRadius: '0',
    borderTopRightRadius: '0',
    '&:before': {
      display: 'none',
    },
  },
  button: {
    borderRadius: '0',
    paddingTop: '14px',
    paddingBottom: '14px',
  },
}))


const Header = props => {
  const classes = useStyles()
  const history = useHistory()

  return (
    <AppBar position="static" className={classes.header} elevation={0}>
      <div className={classes.logos}>
        <img src={props.logo} alt={props.name} className={classes.logo}
             onClick={() => history.push(routes.SIGN_IN_EMAIL)}/>
        {props.brand &&
          <img src={props.brand.logo} alt={props.name} className={classes.logoPartner}
               onClick={() => history.push(routes.SIGN_IN_EMAIL)}/>
        }
      </div>
      <div className={classes.partnerLogoWrapper}>
        Agent Portal
      </div>
    </AppBar>
  )
}

export default Header

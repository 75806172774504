import React from 'react'
import {makeStyles} from '@material-ui/core/styles'
import FilledInput from '@material-ui/core/FilledInput'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import InputLabel from '@material-ui/core/InputLabel'
import PublucDeviceSwitch from '../../Common/PublucDeviceSwitch'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import InputAdornment from '@material-ui/core/InputAdornment'
import Typography from '@material-ui/core/Typography'
import {useSelector} from 'react-redux'
import SignInWithGoogle from '../SignInWithGoogle'

const styleOverride = {
  button: {
    borderRadius: '0',
    paddingTop: '14px',
    paddingBottom: '14px',
    marginTop: '25px',
    marginBottom: '25px',
  },
}

const SignInForm = (props) => {
  const defaultFormStyles = useSelector(state => state.sessionDBState.data.defaultFormStyles)
  const staticTexts = useSelector(state => state.textsState.data)
  const customStyles = {...defaultFormStyles, ...styleOverride}
  const useStyles = makeStyles(theme => (customStyles))
  const classes = useStyles()

  const {
    onSubmit,
    onChangeInput,
    password,
    passwordType,
    email,
    error,
    isInvalid,
    togglePasswordType,
    inputValidation,
    fieldsError,
  } = props

  return (
    <Box p={{xs: 2, md: 10}} boxShadow={{xs: 0, sm: 0, md: 6}} className={classes.box}>
      <Typography variant="h1" className={classes.title} component="h1">
        Sign In
      </Typography>

      <SignInWithGoogle
        style={customStyles}
        text={'Sign In with Google'}
      />

      <Typography variant="body2" className={classes.description} component="h2">
        Or sign with Email
      </Typography>

      <form className={classes.form} noValidate autoComplete="off" onSubmit={onSubmit}>
        <FormControl error={!!fieldsError.email || !!error} variant="filled" fullWidth>
          <InputLabel htmlFor="email">{staticTexts.Email}</InputLabel>
          <FilledInput
            id="email"
            className={classes.field}
            value={email}
            onChange={(e) =>
              onChangeInput(e)
            }
            onBlur={(e) => {
              inputValidation(e, staticTexts.Email, true, 'email')
            }}
          />
          <FormHelperText className={classes.marginHelper}>{!!error ? error.message : fieldsError.email}</FormHelperText>
        </FormControl>
        <FormControl error={!!fieldsError.password || !!error} variant="filled" fullWidth>
          <InputLabel htmlFor="password">{staticTexts.Password}</InputLabel>
          <FilledInput
            id="password"
            className={classes.field}
            type={passwordType}
            value={password}
            onChange={(e) => {
              onChangeInput(e)
              inputValidation(e, staticTexts.Password, true, 'password')
            }
            }
            onBlur={(e) => {
              inputValidation(e, staticTexts.Password, true, 'password')
            }}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={togglePasswordType}
                  onMouseDown={(e) => e.preventDefault()}
                >
                  {passwordType === 'password' ? <Visibility/> : <VisibilityOff/>}
                </IconButton>
              </InputAdornment>
            }
          />
          <FormHelperText className={classes.marginHelper}>{fieldsError.password}</FormHelperText>
        </FormControl>

        <PublucDeviceSwitch />

        <Button
          className={classes.button}
          variant={isInvalid ? 'outlined' : 'contained'}
          disabled={isInvalid}
          color="primary"
          type="submit"
          disableElevation
          fullWidth
          size="large">
          {'Submit'}
        </Button>
      </form>
    </Box>
  )
}

export default SignInForm
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import React, {useEffect, useState} from 'react'
import {makeStyles} from '@material-ui/core/styles'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import FilledInput from '@material-ui/core/FilledInput'
import {useHistory} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import * as routes from '../../../constants/routes'
import {db, helpers} from '../../../actions'
import Button from '@material-ui/core/Button'
import AddIcon from '@material-ui/icons/Add';
import Preloader from '../../Preloader'

const styleOverride = {
  button: {
    borderRadius: '0',
    paddingTop: '14px',
    paddingBottom: '14px',
    marginTop: '25px',
    marginBottom: '25px',
  },
  fieldWrap: {
    marginRight: '10px',
    '&:last-child': {
      marginRight: '0',
    },
  },
  fieldWrapSmall: {
    width: '60%',
  },
  fieldWrapBig: {
    width: '140%',
  },
  formWrapper: {
    display: 'flex',
    marginTop: '30px',
  },
  fieldWrapperTrue: {
    marginTop: '20px',
    padding: '5px',
    background: 'rgba(46, 212, 122, 0.12)',
    borderBottom: '#59CD90 5px solid',
    '@media (min-width: 768px)': {
      display: 'flex',
    },
  },
  fieldWrapperFalse: {
    marginTop: '20px',
    padding: '5px',
    background: 'rgba(255, 60, 95, 0.12)',
    borderBottom: '#FF3C5F 5px solid',
    '@media (min-width: 768px)': {
      display: 'flex',
    },
  },
  btnBox: {
    display: 'flex',
    marginTop: '10px',
  },
  buttonSmall: {
    marginRight: '20px',
    width: '120px',
    marginTop: '30px'
  },
}

const UserDashboard = (props) => {
  const {
    setErrorPage,
    fetchAccessConfig,
  } = db

  const dispatch = useDispatch()
  const history = useHistory()
  const defaultFormStyles = useSelector(state => state.sessionDBState.data.defaultFormStyles)
  const authUser = useSelector(state => state.sessionState.authUser)
  const accessConfig = useSelector(state => state.configState.accessConfig)
  const customStyles = {...defaultFormStyles, ...styleOverride}
  const useStyles = makeStyles(theme => (customStyles))
  const classes = useStyles()
  const [loading, setLoading] = useState(true)
  const [checkedData, setCheckedData] = useState([])
  const [inputList, setInputList] = useState([{firstName: '', lastName: '', memberId: ''}])
  const brandName = useSelector(state => state.sessionDBState.data.brandName)

  useEffect(() => {
    if (!authUser) {
      history.push(routes.SIGN_IN_EMAIL)
    }
    else {
      if (!accessConfig) {
        dispatch(
          fetchAccessConfig(),
        )
      }
    }
  }, [authUser, history])

  useEffect(() => {
    // Check if the user UID have access to the form.
    if (authUser) {
      if (accessConfig?.admins?.includes(authUser?.uid)) {
        setLoading(false)
      }
      else if (accessConfig?.admins?.includes(authUser?.uid) === false) (
        dispatch(
          setErrorPage('Access Denied!', 'You don\'t have permission to access this page.', 'error', false, 'Sign out', routes.SIGN_OUT)
        )
      )
      else {
        setLoading(true)
      }
    }
  }, [accessConfig, authUser])


  const checkData = () => {
    setLoading(true)
    helpers.getMilitaryStatus(inputList, brandName)
      .then(returnedData => {
        setCheckedData(returnedData.data)
        setInputList([{firstName: '', lastName: '', memberId: ''}])
        setLoading(false)
      })
  }

  const toInputUppercase = e => {
    e.target.value = ("" + e.target.value).toUpperCase();
  };

  const handleInputChange = (e, index) => {
    const {name, value} = e.target
    const list = [...inputList]
    list[index][name] = value
    setInputList(list)
  }

  const handleAddClick = () => {
    setInputList([...inputList, {firstName: '', lastName: '', memberId: ''}])
  }

  if (loading) {
    return (
      <Preloader title={'Checking data... Please wait...'}/>
    )
  }
  else {
    return (
      <React.Fragment>
        <Box p={{xs: 2, md: 4}} boxShadow={{xs: 0, sm: 0, md: 6}} className={classes.box}>
          {checkedData.length > 0 &&

          <React.Fragment>
            <Typography variant="h1" className={classes.title} component="h1">
              Returned checked users:
            </Typography>

            {checkedData.map((x, i) => {
              return (
                <div className={x.status ? classes.fieldWrapperTrue : classes.fieldWrapperFalse}
                     key={'formwrapperresult' + i}>
                  <FormControl variant="filled" fullWidth className={classes.fieldWrap}>
                    <InputLabel data-id={x.firstNameError ? "wrong" : "correct"} htmlFor="firstName">First Name</InputLabel>
                    <FilledInput
                      key={'fr' + i}
                      id="firstName"
                      name="firstName"
                      className={x.firstNameError ? classes.fieldTextError : classes.fieldText}
                      value={x.firstName}
                    />
                  </FormControl>
                  <FormControl variant="filled" fullWidth className={classes.fieldWrap}>
                    <InputLabel data-id={x.lastNameError ? "wrong" : "correct"} htmlFor="lastName">Last Name</InputLabel>
                    <FilledInput
                      key={'ef' + i}

                      id="lastName"
                      name="lastName"
                      className={x.lastNameError ? classes.fieldTextError : classes.fieldText}
                      value={x.lastName}
                    />
                  </FormControl>
                  <FormControl variant="filled" fullWidth className={classes.fieldWrap}>
                    <InputLabel data-id={x.memberIdError ? "wrong" : "correct"} htmlFor="memberId">Member Id</InputLabel>
                    <FilledInput
                      key={'dsf' + i}

                      id="memberId"
                      name="memberId"
                      className={x.memberIdError ? classes.fieldTextError : classes.fieldText}
                      value={x.memberId}
                    />
                  </FormControl>
                  <FormControl variant="filled" fullWidth className={classes.fieldWrapSmall}>
                    <InputLabel data-id={x.status ? "correct" : "wrong"} htmlFor="status">Status</InputLabel>
                    <FilledInput
                      key={'dsewf' + i}
                      id="status"
                      name="status"
                      className={classes.fieldText}
                      value={x.status}
                    />
                  </FormControl>
                  {x.userRole &&
                  <FormControl variant="filled" fullWidth className={classes.fieldWrapBig}>
                    <InputLabel data-id={x.userRole ? "correct" : "wrong"} htmlFor="role">Role</InputLabel>
                    <FilledInput
                      key={'userRoleds' + i}
                      id="role"
                      name="role"
                      className={classes.fieldText}
                      value={x.userRole.toUpperCase()}
                    />
                  </FormControl>
                  }
                </div>
              )
            })}
          </React.Fragment>
          }
          {checkedData.length > 0 &&
          <Typography variant="h1" className={classes.title} component="h1">
            <br/>
            Check again below:
          </Typography>
          }
          {checkedData.length === 0 &&
          <Typography variant="h1" className={classes.title} component="h1">
            Enter info below:
          </Typography>
          }
          {inputList.map((x, i) => {
            return (
              <div className={classes.form} key={'formdiv' + i}>
                <div className={classes.formWrapper} key={'formwrapper' + i}>
                  <FormControl variant="filled" fullWidth className={classes.fieldWrap}>
                    <InputLabel htmlFor="firstName">First Name</InputLabel>
                    <FilledInput
                      key={'f' + i}
                      id="firstName"
                      name="firstName"
                      className={classes.field}
                      value={x.firstName}
                      onChange={e => handleInputChange(e, i)}
                    />
                  </FormControl>
                  <FormControl variant="filled" fullWidth className={classes.fieldWrap}>
                    <InputLabel htmlFor="lastName">Last Name</InputLabel>
                    <FilledInput
                      key={'l' + i}
                      id="lastName"
                      name="lastName"
                      className={classes.field}
                      value={x.lastName}
                      onChange={e => handleInputChange(e, i)}
                    />
                  </FormControl>
                  <FormControl variant="filled" fullWidth className={classes.fieldWrap}>
                    <InputLabel htmlFor="memberId">Member Id</InputLabel>
                    <FilledInput
                      key={'m' + i}
                      id="memberId"
                      name="memberId"
                      className={classes.field}
                      value={x.memberId}
                      onInput={toInputUppercase}
                      onChange={e => handleInputChange(e, i)}
                    />
                  </FormControl>
                </div>
                <div className={classes.btnBox}>
                  {inputList.length - 1 === i &&
                  <Button
                    key={'bt' + i}
                    className={classes.buttonSmall}
                    variant={'contained'}
                    color="primary"
                    type="submit"
                    disableElevation
                    fullWidth
                    onClick={handleAddClick}
                    startIcon={<AddIcon />}
                    size="small">
                    {'Add Check'}
                  </Button>
                  }
                </div>
              </div>
            )
          })}
          <br/>
          <br/>
          <Button
            onClick={checkData}
            className={classes.button}
            variant={'contained'}
            color="secondary"
            disableElevation
            fullWidth
            size="large">
            Check Data
          </Button>
        </Box>
        <br/>
        <br/>
        <Typography variant="body2" className={classes.description} component="h2">
          You're currently signed in as <b>{authUser.email}</b>
          <br/>
          <Button
            className={classes.button}
            variant={'contained'}
            color="primary"
            type="submit"
            disableElevation
            onClick={(e) => {
              e.preventDefault()
              history.push(routes.SIGN_OUT)
            }}
            size="small">
            {'Sign out'}
          </Button>
        </Typography>
      </React.Fragment>
    )
  }
}

export default UserDashboard

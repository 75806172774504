import firebase from 'firebase/app'
import {dbRef, functionsRef} from '../firebase/firebase'
import store from '../store'
import {load} from 'recaptcha-v3'

// Create initial user record.
export const doCreateUser = (data) => {
  const state = store.getState()
  const source = state.sessionDBState.data.returnType

  let dateNow = (new Date()).getTime()
  let additionalInfo = {
    created: dateNow,
    changed: dateNow,
    signupSource: source !== 'widget' ? 'platform' : 'widget',
    modifiedBy: 'authApp',
    revisionId: 1,
  }

  const createUser = functionsRef.httpsCallable('groupAuthHelpers-doCreateUser')
  return createUser({...data, ...additionalInfo})
}

// Initiate recaptcha.
export const recaptchaChecker = (action) => {
  const key = process.env.REACT_APP_RECAPTCHA_KEY

  // If action is login, then check the token and return result.
  if (action === 'login') {
    return load(key)
      .then((recaptcha) => {
        return recaptcha.execute(action).then((token) => {
          const recaptchaChecker = functionsRef.httpsCallable('groupAuthHelpers-recaptchaChecker')
          return recaptchaChecker({token})
        })
      })
  }
  // If action is homepage, just initiate recaptcha for context and statistics.
  else if (action === 'homepage') {
    return load(key).then((recaptcha) => {
      return recaptcha.execute(action)
    })
  }
}

// Check if user already exists in the DB.
export const checkExistingUser = (email, facebook, skipAuthCheck) => {
  const checkExistingUser = functionsRef.httpsCallable('groupAuthHelpers-checkExistingUser')
  return checkExistingUser({email, facebook, skipAuthCheck})
}

// Verify Auth Email.
export const verifiedEmailFlag = (email) => {
  const verifiedEmailFlag = functionsRef.httpsCallable('groupAuthHelpers-verifyAuthEmail')
  return verifiedEmailFlag({email})
}

// Check if user's phone already exists in the DB.
export const checkExistingPhone = (phone) => {
  const checkExistingPhone = functionsRef.httpsCallable('groupAuthHelpers-checkExistingPhone')
  return checkExistingPhone({phone})
}

// Check email domain.
export const doVerifyEmailDomain = (email) => {
  const verifyEmailDomain = functionsRef.httpsCallable('groupAuthHelpers-verifyEmailDomain')

  return verifyEmailDomain({email})
}

// Get authToken.
export const doGetCustomToken = () => {
  const getCustomToken = functionsRef.httpsCallable('groupAuthAccounts-getCustomToken')

  return getCustomToken()
}

// Get authToken.
export const doGetCsrfToken = () => {
  const getCsrfToken = functionsRef.httpsCallable('groupAuthAccounts-getCsrfToken')

  return getCsrfToken()
}

// Log out user from Platform.
export const doLogoutDrupalUser = () => {
  const drupalUserLogout = functionsRef.httpsCallable('groupAuthAccounts-drupalUserLogout')
  return drupalUserLogout()
}

// Get authToken.
export const doGetJWTRedirectUrl = (data) => {
  const getJWTRedirectUrl = functionsRef.httpsCallable('groupAuthZendesk-jwtAuth')

  return getJWTRedirectUrl(data)
}

// Get authToken.
export const getMilitaryStatus = (membersList, brandName) => {
  const getMilitaryStatus = functionsRef.httpsCallable('groupAgentHelpers-getMilitaryStatus')

  return getMilitaryStatus({members: membersList, brand: brandName})
}

// Update the current user record.
export const addAccountInfo = (account, uid) => {
  let changedTime = {
    changed: (new Date()).getTime(),
    modifiedBy: 'authApp',
    revisionId: firebase.firestore.FieldValue.increment(1),
  }

  return dbRef.collection('veterans')
    .doc(uid)
    .set(
      {
        ...account,
        ...changedTime,
      },
      {merge: true},
    )
}

// Update/Write session into the DB.
export const writeSession = (updates, sessionId) =>
  dbRef.collection('sessions')
    .doc(sessionId)
    .set(updates, {merge: true})


// Update/Write login session into the DB.
export const writeLoginSession = (updates, sessionId) =>
  dbRef.collection('sessions')
    .doc(sessionId)
    .collection('login_session')
    .doc(sessionId)
    .set(updates)

// Check if name is valid
export const isValidName = (text) => {
  const reg = RegExp('^[ a-zA-Z\'.-]*$')
  if (
    !!text &&
    reg.test(String(text))
  ) {
    return true
  }
  else {
    return false
  }
}

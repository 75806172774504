import {dbRef} from '../firebase/firebase'
import {
  FETCH_ACCOUNT,
  FETCH_ACCOUNT_UPDATE,
  FETCH_ACCESS_CONFIG,
  FETCH_STATIC_TEXT,
  SESSION_DB_UPDATE,
  SET_ERROR_PAGE,
} from '../constants/actiontypes'

let veteranListener = () => {
}

export const onSetAuthUser = (authUser) => dispatch => {
  dispatch({
    type: 'AUTH_USER_SET',
    authUser,
  })

  // If user logs out, reset accountData.
  if (!authUser) {
    veteranListener()
    dispatch({
      type: 'FETCH_ACCOUNT',
      payload: null,
    })
  }
}
// Update/Write session into the Store.
export const updateSession = (updates) => dispatch => {
  dispatch({
    type: SESSION_DB_UPDATE,
    payload: updates,
  })
}

export const updateAccountData = (data) => dispatch =>
  dispatch({
    type: FETCH_ACCOUNT_UPDATE,
    payload: data,
  })

// Get all the static texts for the site.
export const fetchStaticTexts = () => dispatch => {
  dbRef.collection('AuthStaticText').doc('texts').get().then((doc) => {
    if (doc.exists) {
      dispatch({
        type: FETCH_STATIC_TEXT,
        payload: doc.data(),
      })
    }
    else {
      dispatch({
        type: FETCH_STATIC_TEXT,
        payload: false,
      })
    }
  })
}

// Get access config.
export const fetchAccessConfig = () => dispatch => {
  dbRef.collection('config')
    .doc('agentRedeem')
    .get()
    .then((doc) => {
      if (doc.exists) {
        dispatch({
          type: FETCH_ACCESS_CONFIG,
          payload: doc.data(),
        })
      }
      else {
        dispatch({
          type: FETCH_ACCESS_CONFIG,
          payload: false,
        })
      }
    })
}

export const fetchBrand = (brandName) => dispatch => {
  dbRef.collection('nodes_brand')
    .where('id', '==', brandName)
    .get()
    .then((snapshot) => {
      if (snapshot.size === 1) {
        dispatch({
          type: SESSION_DB_UPDATE,
          payload: {brand: snapshot.docs[0].data()},
        })
      }
    })
    .catch(e => console.log('error:', e))
}

// Dispatch the error page.
export const setErrorPage = (alertTitle, alertDesc, alertType, description, buttonTitle, buttonPage, buttonExternal, buttonClose, buttonSignOut, descriptionTitle) => dispatch => {
  dispatch({
    type: SET_ERROR_PAGE,
    payload: {
      alert: {
        title: alertTitle ? alertTitle : false,
        desc: alertDesc ? alertDesc : false,
        type: alertType ? alertType : false,
      },
      desc: description ? description : false,
      descTitle: descriptionTitle ? descriptionTitle : false,
      btn: {
        title: buttonTitle ? buttonTitle : false,
        page: buttonPage ? buttonPage : false,
        external: buttonExternal ? buttonExternal : false,
        close: buttonClose ? buttonClose : false,
        signout: buttonSignOut ? buttonSignOut : false,
      },
      active: true,
    },
  })
}

// Clear the error page.
export const clearErrorPage = () => dispatch => {
  dispatch({
    type: SET_ERROR_PAGE,
    payload: {
      alert: {
        title: false,
        desc: false,
        type: false,
      },
      desc: false,
      descTitle: false,
      btn: {
        title: false,
        page: false,
        external: false,
        close: false,
        signout: false,
      },
      active: false,
    },
  })
}

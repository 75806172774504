import React, {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Route, Redirect, Switch} from 'react-router-dom'
import {db} from '../../actions'
import {styles} from '../../styles'
import * as routes from '../../constants/routes'
import {Container} from '@material-ui/core'
import {createTheme, ThemeProvider} from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import * as Sentry from '@sentry/browser'
import {firebase} from '../../firebase'
import SignOut from '../Sign/SignOut'
import Header from '../Common/Header'
import SignInForm from '../Sign/SignInForm'
import UserDashboard from '../Sign/UserDashboard'
import ModalAlert from '../Common/ModalAlert'

const theme = createTheme(styles.theme)
const isEdge = window.navigator.userAgent.indexOf('Edge') !== -1
const isIE = window.navigator.userAgent.indexOf('Trident') !== -1 && !isEdge

const App = () => {
  const dispatch = useDispatch()
  const errorPageData = useSelector(state => state.errorState.active)
  const remember = useSelector(state => state.sessionDBState.data.rememberCredentials)
  const brand = useSelector(state => state.sessionDBState.data.brand)
  const brandName = useSelector(state => state.sessionDBState.data.brandName)

  const {
    updateSession,
    fetchStaticTexts,
    setErrorPage,
    onSetAuthUser,
    fetchBrand,
  } = db

  useEffect(() => {
    // State the version of app.
    if (process.env.REACT_APP_ENV === 'dev') {
      console.log(process.env.REACT_APP_VERSION)
    }

    // Set default styles in local session.
    dispatch(
      updateSession({
        defaultFormStyles: styles.defaultFormStyles,
        defaultDashboardStyles: styles.defaultDashboardStyles,
        defaultAlertStyles: styles.defaultAlertStyles,
      }),
    )

    // Get all the static texts.
    dispatch(
      fetchStaticTexts(),
    )

    firebase.authRef.onAuthStateChanged(authUserRef => {
      if (authUserRef) {
        dispatch(
          onSetAuthUser(authUserRef),
        )
      }
      else {
        dispatch(
          onSetAuthUser(null),
        )
      }
    })
    // eslint-disable-next-line
  }, [])

  // Hide header on specific pages.
  const trySetPersistence = () => {
    firebase.authRef.setPersistence(
      remember ? firebase.persistenceLocal : firebase.persistenceNone,
    )
      .catch((error) => {
        dispatch(
          updateSession({
            remember: false,
          }),
        )
        Sentry.captureException(error)
        console.error(error)
      })
  }

  useEffect(() => {
    trySetPersistence()
    // eslint-disable-next-line
  }, [remember])

  useEffect(() => {
    if (brandName) {
      dispatch(
        fetchBrand(brandName),
      )
    }
  }, [brandName])

  useEffect(() => {
    if (isIE) {
      dispatch(
        setErrorPage(false, false, 'error', 'To enjoy our website and access use @chrome @safari, or @mozilla.', false, false, false, false, false, 'Wait! Did you know that your browser is likely out of date?'),
      )
    }
    // eslint-disable-next-line
  }, [isIE, errorPageData])

  // Set all the routes after the info is loaded.
  // Note: we are passing just the info that is needed, and filter the static data manually.
  return (
    <React.Fragment>
      <ThemeProvider theme={theme}>
        <CssBaseline/>
        <Header
          logo={'/assets/images/we-salute-logo.svg'}
          name={'Agent App'}
          brand={brand ? brand : null}
        />
        <Container fixed maxWidth={'md'} className="app" disableGutters>
          {errorPageData &&
            <ModalAlert/>
          }

          <Switch>
            <Route exact path={'/'} render={() => <Redirect to={routes.SIGN_IN_EMAIL}/>}/>
            <Route exact path={routes.SIGN_IN_EMAIL} render={() => <SignInForm/>}/>
            <Route exact path={routes.USER_DASHBOARD} render={() => <UserDashboard/>}/>
            <Route exact path={routes.SIGN_OUT} render={() => <SignOut/>}/>
            <Route path={'/:brand'} render={() => <SignInForm/>}/>
          </Switch>
        </Container>
      </ThemeProvider>
    </React.Fragment>
  )
}

export default App

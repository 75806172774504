import React from 'react'
import * as routes from '../../../constants/routes'
import {auth, db, helpers} from '../../../actions'
import {useHistory} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import {makeStyles} from '@material-ui/core/styles'
import SvgIcon from '@material-ui/core/SvgIcon'
import {ReactComponent as GoogleIcon} from './google-icon.svg'
import Button from '@material-ui/core/Button'
import * as Sentry from '@sentry/browser'

const SignInWithGoogle = (props) => {

  const styleChanges = {
    icon: {
      ...props.style.icon,
      padding: '3px',
    },
  }
  const useStyles = makeStyles(theme => ({...props.style, ...styleChanges}))
  const classes = useStyles()

  const history = useHistory()
  const staticTexts = useSelector(state => state.textsState.data)
  const {setErrorPage} = db
  const dispatch = useDispatch()

  const signInWithGoogle = () => {
    // Sign in the user with Google provider.
    auth.doSignInWithGoogle()
      .then(authUser => {
        const email = authUser.user?.email
        const firstName = authUser.additionalUserInfo?.profile?.given_name
        const lastName = authUser.additionalUserInfo?.profile?.family_name

        // Create a user in Firebase Database if it is a new user.
        if (authUser.additionalUserInfo.isNewUser) {
          checkNewUser(authUser, email, firstName, lastName)
        }
        else {
          history.push(routes.USER_DASHBOARD)
        }
      })
      .catch(error => {
        Sentry.captureException(error)
        console.error('google-sign-in', error)

        if (error.code === 'auth/operation-not-supported-in-this-environment') {
          dispatch(setErrorPage(staticTexts.IncognitoModeErrorTitle, staticTexts.IncognitoModeErrorDescription, 'error', false, staticTexts.GeneralErrorClose, false, false, true))
        }
        else if (error.email) {
          // Process Firebase Auth existing accounts messages.
          // The error object will contain the email for the account.
          auth.processSignInMethodsForEmail(error.email, staticTexts, dispatch, setErrorPage, error)
            .catch(error => console.error(error))
        }
        else {
          dispatch(setErrorPage(staticTexts.GeneralErrorTitle, error.message, 'error', false, staticTexts.GeneralErrorClose, false, false, true))
        }
      })
  }

  const checkNewUser = (authUser, email, firstName, lastName) => {
    // Check if newly created user
    helpers.checkExistingUser(email ? email : authUser.user.email, null, true)
      .then((existUserEmail) => {
        setShowEmailForm(false)
        if (!existUserEmail.data.exist) {
          createNewUser(authUser, email, firstName, lastName)
        }
        else if (existUserEmail.data?.id === authUser.user.uid) {
          helpers.addAccountInfo({
            firstname: firstName,
            lastname: lastName,
            email: email,
            emailVerified: !!authUser.user?.emailVerified,
          }, authUser.user.uid)
          // Redirect to next step upon success.
          history.push(routes.USER_DASHBOARD)
        }
      })
      .catch(error => {
        Sentry.captureException(error)
        console.error('google-check-existing-user', error)
      })
  }

  const createNewUser = (authUser, email, firstName, lastName) => {
    helpers.doCreateUser({
        firstname: firstName,
        lastname: lastName,
        email: email,
        googleId: authUser.additionalUserInfo.profile.id,
        emailVerified: !!authUser.user?.emailVerified,
      },
    )
      .then(() => {
        // Redirect to next step upon success.
        history.push(routes.USER_DASHBOARD)
      })
      .catch(error => {
        Sentry.captureException(error)
        console.error('google-create-new-user', error)

        // Set state error with the catch error.
        dispatch(setErrorPage(staticTexts.GeneralErrorTitle, error.message, 'error', false, staticTexts.GeneralErrorClose, false, false, true))
      })
  }

  return (
    <React.Fragment>
      <Button
        className={classes.button}
        onClick={() => signInWithGoogle()}
        color="primary"
        disableElevation
        fullWidth
        size="large">
        <SvgIcon
          component={GoogleIcon}
          className={classes.icon}
        />
        {props.text ? props.text : staticTexts.GoogleSignUp}
      </Button>
    </React.Fragment>
  )
}

export default SignInWithGoogle

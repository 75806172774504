export const FETCH_ACCOUNT = 'FETCH_ACCOUNT'
export const FETCH_ACCOUNT_UPDATE = 'FETCH_ACCOUNT_UPDATE'
export const FETCH_ACCOUNT_CREATE = 'FETCH_ACCOUNT_CREATE'
export const FETCH_DEALS = 'FETCH_DEALS'
export const NO_DEALS_FOUND = 'NO_DEALS_FOUND'
export const FETCH_PLANS = 'FETCH_PLANS'
export const SELECT_PLAN = 'SELECT_PLAN'
export const FETCH_STATIC_TEXT = 'FETCH_STATIC_TEXT'
export const FETCH_BRAND_INFO = 'FETCH_BRAND_INFO'
export const SESSION_DB_ID = 'SESSION_DB_ID'
export const SESSION_DB_UPDATE = 'SESSION_DB_UPDATE'
export const FETCH_CONFIG = 'FETCH_CONFIG'
export const FETCH_PRODUCTS = 'FETCH_PRODUCTS'
export const FETCH_QUIZ = 'FETCH_QUIZ'
export const FETCH_QUIZ_ERROR = 'FETCH_QUIZ_ERROR'
export const QUIZ_ANSWERS = 'QUIZ_ANSWERS'
export const QUIZ_RESET = 'QUIZ_RESET'
export const SET_ERROR_PAGE = 'SET_ERROR_PAGE'
export const FETCH_MILINFO = 'FETCH_MILINFO'
export const CAPTURE_ID_ERROR = 'CAPTURE_ID_ERROR'
export const CAPTURE_INFO_ID_DONE = 'CAPTURE_INFO_ID_DONE'
export const CAPTURE_FACE_ID_DONE = 'CAPTURE_FACE_ID_DONE'
export const FETCH_ACCESS_CONFIG = 'FETCH_ACCESS_CONFIG'

const INITIAL_STATE = {
  accessConfig: null,
}

const applyAccessConfig = (state, action) => ({
  accessConfig: action.payload,
})

function configReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'FETCH_ACCESS_CONFIG' : {
      if (action.payload) {
        return applyAccessConfig(state, action)
      }
      else {
        return false
      }
    }
    default :
      return state
  }
}

export default configReducer

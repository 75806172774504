const INITIAL_STATE = {
  id: '',
  data: {
    rememberCredentials: true,
  },
}

function sessionDBReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'SESSION_DB_ID' : {
      return {
        ...state,
        id: action.payload,
      }
    }
    case 'SESSION_DB_UPDATE' : {
      // return state
      return {
        id: state.id,
        data: {...state.data, ...action.payload},
      }
    }
    default :
      return state
  }
}

export default sessionDBReducer
